var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CustomTooltip',{staticClass:"markets-hint",scopedSlots:_vm._u([{key:"headline",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('markets.headerTooltip.title'))+" ")]},proxy:true},{key:"hoverContent",fn:function(){return [_c('i',{staticClass:"icon-Question-mark markets-hint__icon"})]},proxy:true}])},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('markets.headerTooltip.description'))+" ")]),_c('div',{staticClass:"markets-hint__indicators-bar"},[_c('div',{staticClass:"good-dark"},[_vm._v(_vm._s(_vm.$t('high')))]),_c('div',{staticClass:"good"},[_vm._v(_vm._s(_vm.$t('medHigh')))]),_c('div',{staticClass:"fair"},[_vm._v(_vm._s(_vm.$t('medLow')))]),_c('div',{staticClass:"poor"},[_vm._v(_vm._s(_vm.$t('poor')))])]),_c('div',{class:[
        'markets-hint__indicator',
        'markets-hint__indicator--no-data'
    ]},[_c('div',{domProps:{"textContent":_vm._s(_vm.$t('noData'))}}),_vm._v(" "+_vm._s(_vm.$t('markets.headerTooltip.noDataFound'))+" ")]),_c('div',{class:[
            'markets-hint__indicator',
            'markets-hint__indicator--na',
        ]},[_c('div',{domProps:{"textContent":_vm._s(_vm.$t('na'))}}),_vm._v(" "+_vm._s(_vm.$t('markets.headerTooltip.notFoundIn10Pages'))+" ")]),_c('div',{staticClass:"markets-hint__indicator"},[_c('div',{domProps:{"textContent":_vm._s(_vm.$t('outOfRange'))}}),_vm._v(" "+_vm._s(_vm.$t('markets.headerTooltip.outOfRange'))+" ")]),_c('div',{staticClass:"markets-hint__indicator"},[_c('div',[_c('span',{staticClass:"red"},[_vm._v("▼")]),_c('span',{staticClass:"green"},[_vm._v("▲")])]),_vm._v(" "+_vm._s(_vm.$t('markets.headerTooltip.arrows'))+" ")]),_c('div',{staticClass:"markets-hint__indicator"},[_c('div',[_vm._v(" Promotion ")]),_vm._v(" "+_vm._s(_vm.$t('markets.headerTooltip.promotions'))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }