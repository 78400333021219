
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import type MarketsFiltersService from '@/modules/markets/markets-filters.service';
import type ProvidersService from '@/modules/providers/providers.service';
import type CompsetsService from '@/modules/compsets/compsets.service';

@Component({
    components: { CustomSelect },
})
export default class ProviderTypeFilter extends Vue {
    @inject(KEY.MarketsFiltersService) private marketsFiltersService!: MarketsFiltersService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    get provider() {
        return this.marketsFiltersService.currentProvider;
    }

    set provider(value) {
        this.marketsFiltersService.saveProvider(value);
    }

    get providerItems(): Item[] {
        return this.providersService
            .toItemsList(this.compsetsService.currentCompset?.marketProviders || []);
    }
}
